import { lazy } from "react";
import Loadable from "src/routes/Loadable";

const Addsales = Loadable(lazy(() => import("src/pages/admin/sales/index")));

const Sales = [
  {
    path: "addsales",
    children: [{ index: true, element: <Addsales /> }],
  },
];

export default Sales;
